import { call, put, takeEvery } from "redux-saga/effects";

import { ManageUserSuccess, ManageUserFailure } from "store/actions/Settings/ManageUser";
import { MANAGE_USER } from "store/constants/Settings/ManageUser";

import getRequest from "util/APIHelperGet";
import { baseUrl } from "util/APIBaseUrl";

export const fetchManageUserRequest = async (actions) => {
  // eslint-disable-next-line no-return-await
  // return await getRequest(`${baseUrl}/${actions.payload.request}`)
  let payload = {
    limit: actions.payload.rowsPerPage,
    page: actions.payload.page,
  };

  if (actions.payload?.sortBy?.field) {
    payload = {
      ...payload,
      orderBy: JSON.stringify({
        field: actions.payload?.sortBy?.field,
        direction: actions.payload?.sortBy?.direction,
      }),
    };
  }

  if (actions.payload?.searchKeyword) {
    payload = {
      ...payload,
      searchKeyword: actions.payload?.searchKeyword,
    };
  } else if (actions.payload?.searchEmployee) {
    payload = {
      ...payload,
      filter: JSON.stringify({
        name: [actions.payload?.searchEmployee],
      }),
    };
  }

  return await getRequest(`${baseUrl}/${actions.payload.request}?${new URLSearchParams(payload)}`)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

function* fetchManageUser(params) {
  try {
    const response = yield call(fetchManageUserRequest, params);
    yield put(ManageUserSuccess(response));
  } catch (error) {
    console.error(error);
    yield put(ManageUserFailure(error));
  }
}

export default function* rootSaga() {
  yield takeEvery(MANAGE_USER, fetchManageUser);
}
