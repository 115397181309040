import React, { useMemo, createRef, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useForm, FormProvider } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Avatar, Divider } from "@mui/material";

import Text from "components/common/Text";
import Button from "components/common/Button";
import PhoneInput from "components/common/PhoneInput";
import PageLoader from "components/common/PageLoader";
import { usePermissions } from "components/Hook";
import { AlertType } from "components/common/Snackbar";
import ImageCropperModal from "components/common/ImageCropperModal";
import SearchableAutoComplete from "components/common/AutoComplete";
import { isEmptyObject, isValidResponse } from "components/Screens/CommonUtils";
import ManagedEmployeeList from "components/Screens/SettingV2/AddUser/ManagedEmployeeList/index";

import { DropdownData } from "store/actions/DropdownData";
import { showSnackbar } from "store/actions/Utility";
import { AddUser, ResetAddUser } from "store/actions/Settings/AddUser";
import { GetUserDetails, ResetGetUserDetails } from "store/actions/Settings/GetUserDetails";
import { baseUrl } from "util/APIBaseUrl";

import CustomerUserSchema from "validations/CustomerUserSchema";
import { EditOutlined, ArrowBackOutlined } from "@mui/icons-material";

import "./add-user.scss";
import { CardHeader } from "components/common/Card";

function AddCustomerUser(props) {
  const { navigatedFor, paramId, setAddorEditUser, editableUser, managersList, customerId } = props;

  const photoInput = createRef();
  const dispatch = useDispatch();
  const { loggedInUserType, dropdownData } = usePermissions();

  const [showImageCropperModal, setShowImageCropperModal] = useState(false);
  const [croppedUserImage, setCroppedUserImage] = useState();
  const [dropdownData_, setDropdownData_] = useState({});
  const [imageSrc, setImageSrc] = useState();
  const [imageFile, setImageFile] = useState();
  const [permDropdownOption, setPermDropdownOption] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isFileUploadError, setIsFileUploadError] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const getUserDetails = useSelector((state) => state.GetUserDetails.getUserDetails);
  const addUser = useSelector((state) => state.AddUser.addUser || {});

  const actionMode = useMemo(
    () => ({
      editUser: {
        customer: {
          logoRequest: `customerUser/${paramId}/logo?id=${getUserDetails?.payload?.pictureVersion}`,
        },
        edge: {
          logoRequest: `customerUser/${paramId}/logo?id=${getUserDetails?.payload?.pictureVersion}`,
        },
      },

      addUser: {
        customer: {
          logoRequest: `customerUser/${addUser?.payload?.data?.customerUserId}/logo`,
        },
        edge: {
          logoRequest: `customerUser/${addUser?.payload?.data?.customerUserId}/logo`,
        },
      },
    }),
    [getUserDetails, addUser, paramId]
  );

  const methods = useForm({
    resolver: yupResolver(CustomerUserSchema),
    mode: "onChange",
    defaultValues: {
      firstName: editableUser?.firstName || "",
      lastName: editableUser?.lastName || "",
      email: editableUser?.email || "",
      phoneNumber: editableUser?.phoneNumber || "",
      permissions: (editableUser?.permissions && editableUser?.permissions[0]) || "",
    },
  });

  const {
    handleSubmit,
    formState: { errors, isValid },
    trigger,
  } = methods;

  const onSubmit = async (data) => {
    if (isValid && !isSubmitting) {
      setIsSubmitting(true);
      setIsLoading(true);
      try {
        if (data?.permissions) {
          const userActionMode = {
            editUser: {
              customer: `v2/customerUser/${paramId}`,
              edge: `v2/customerUser/${paramId}`,
              method: "PATCH",
            },

            addUser: {
              customer: "customerUser/",
              edge: "customerUser/",
              method: "POST",
            },
          };

          await dispatch(
            AddUser({
              request: userActionMode[navigatedFor][loggedInUserType],
              userData: data,
              customerId,
              method: userActionMode[navigatedFor]?.method,
            })
          );
        } else {
          setIsLoading(false);
          setIsSubmitting(false);
        }
      } catch (error) {
        dispatch(showSnackbar({ type: AlertType.error, message: error?.message, dispatch }));
        setIsLoading(false);
      }
    }
  };

  const handleSaveClick = async () => {
    if (!isSubmitting) {
      const result = await trigger();
      if (result) {
        handleSubmit(onSubmit)();
      }
    }
  };

  const uploadProfilePic = () => {
    photoInput.current.click();
  };

  function readFile(file) {
    return new Promise((resolve) => {
      const reader = new FileReader();

      reader.addEventListener(
        "load",
        () => {
          resolve(reader.result);
        },
        false
      );
      reader.readAsDataURL(file);
    });
  }

  const handleImageChange = async (e) => {
    e.preventDefault();

    const file = e.target.files[0];
    e.target.value = "";

    if (file.size / 1024 / 1024 > 2) setIsFileUploadError(true);
    else setIsFileUploadError(false);

    const imageDataUrl = await readFile(file);
    setImageSrc(imageDataUrl);

    setShowImageCropperModal(true);
  };

  const postLogo = async (actions) => {
    const response = await fetch(`${baseUrl}/${actions.request}`, {
      method: "POST",
      headers: {},
      body: actions.formData,
    });
    if (!response.ok) {
      return;
    }
    await response.json();
  };

  const handleImageSave = async (param) => {
    const formData = new FormData();
    const imageDataUrl = await readFile(param);
    setCroppedUserImage(imageDataUrl);

    formData?.append("file", param);

    setImageFile(formData);
    setShowImageCropperModal(false);

    if (navigatedFor === "editUser") {
      postLogo({
        request: actionMode[navigatedFor][loggedInUserType].logoRequest,
        formData,
      });
    }
  };

  useEffect(() => {
    if (isValidResponse(addUser)) {
      if (navigatedFor === "addUser") {
        postLogo({
          request: actionMode[navigatedFor][loggedInUserType].logoRequest,
          formData: imageFile,
        });
      }

      dispatch(
        showSnackbar({
          type: AlertType?.success,
          message: navigatedFor === "editUser" ? "User updated successfully" : "User added successfully",
          dispatch,
        })
      );
      setAddorEditUser({
        open: false,
        mode: null,
      });
    } else if (Object.keys(addUser).length && addUser?.ok == false) {
      dispatch(
        showSnackbar({
          type: AlertType?.error,
          message: addUser?.payload?.error[0]?.description,
          dispatch,
        })
      );

      setIsSubmitting(false);
      setIsLoading(false);
    }
    return () => {
      dispatch(ResetAddUser());
      dispatch(ResetGetUserDetails({}));
    };
  }, [addUser]);

  useEffect(() => {
    setIsLoading(true);
    let loggedInUser = {};
    let permissionsOption = [];

    loggedInUser = JSON.parse(localStorage.getItem("loggedInUser"));

    dispatch(DropdownData());

    if (isEmptyObject(loggedInUser)) {
      permissionsOption = [
        {
          title: "Customer Admin",
          description: "Customer admin can add new users, edit company and payment details ",
          value: "CustomerAdmin",
        },
        {
          title: "Customer Employee",
          description: "Limited access user",
          value: "CustomerEmployee",
        },
      ];

      setPermDropdownOption(
        permissionsOption.map((perm) => ({
          label: perm.title,
          value: perm.value,
          description: perm.description,
          render: (option) => {
            return (
              <CardHeader
                key="title"
                subheader={option.description}
                isSmallHeading
                title={option.label}
                sx={{ padding: 0 }}
              />
            );
          },
        }))
      );
    }

    if (isEmptyObject(loggedInUser) && navigatedFor === "editUser" && paramId) {
      const userDetailsRequest = {
        customer: `customerUser/${paramId}`,
        edge: `customerUser/${paramId}`,
      };

      dispatch(
        GetUserDetails({
          request: userDetailsRequest[loggedInUserType],
        })
      );
    }

    if (!Object.keys(dropdownData_).length) {
      const countries = [];
      const edgePermissions = [];
      const customerPermissions = [];
      let Permissions = [];

      if (isValidResponse(dropdownData)) {
        dropdownData?.payload?.country?.map((data) => {
          return countries.push({
            key: data.countryCode2,
            value: data.countryCode,
          });
        });
        dropdownData?.payload?.edgePermission?.map((perm) => {
          return edgePermissions.push({
            key: perm.description,
            value: perm.permissionName,
            valueDescription: perm.permissionName == "EdgeAdmin" ? "Admin" : "Employee",
          });
        });

        dropdownData?.payload?.customerPermission?.map((perm) => {
          return customerPermissions.push({
            key: perm.description,
            value: perm.permissionName,
            valueDescription: perm.permissionName == "CustomerAdmin" ? "Admin" : "Employee",
          });
        });

        if (loggedInUser?.type == "customer") {
          Permissions = customerPermissions;
        } else if (loggedInUser?.type == "edge") {
          Permissions = edgePermissions;
        }

        setDropdownData_({
          ...dropdownData_,
          countries,
          Permissions,
        });
      }
    }
    setIsLoading(false);
  }, []);

  return (
    <>
      {isLoading && <PageLoader />}
      <ImageCropperModal
        open={showImageCropperModal}
        onClose={() => setShowImageCropperModal(false)}
        imageSrc={imageSrc}
        onSave={handleImageSave}
        isFileUploadError={isFileUploadError}
        errorMessage="The file you've selected is too large. Please upload an image file that is 2MB or smaller."
      />
      <div>
        <FormProvider {...methods}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="flex items-center justify-between">
              <div className="flex items-center space-x-1">
                <ArrowBackOutlined
                  className="cursor-pointer"
                  onClick={() => setAddorEditUser({ open: false, mode: null })}
                />
                <p className="heading-h3-semibold">{navigatedFor == "editUser" ? "Edit a User" : "Add a User"}</p>
              </div>
              <div className="flex items-center gap-05">
                <Button
                  onClick={() => setAddorEditUser({ open: false, mode: null })}
                  label="Cancel"
                  size="large"
                  variant="text"
                />

                <Button
                  isLoading={isLoading}
                  variant="contained"
                  color={!isValid && "disabled"}
                  size="large"
                  label={navigatedFor === "editUser" ? "Save" : "Add User"}
                  type="submit"
                  onClick={handleSaveClick}
                  disabled={!isValid}
                />
              </div>
            </div>
            <div className={`add-user-content ${loggedInUserType === "edge" && "w-70p"}`}>
              <div className="user-img-container">
                <div className="relative cursor-pointer" onClick={uploadProfilePic}>
                  <Avatar
                    variant="rounded"
                    className="user-img-avt"
                    imgProps={{ crossOrigin: "anonymous" }}
                    src={
                      croppedUserImage ??
                      `${baseUrl}/customerUser/${getUserDetails?.payload?.userId}/logo?id=${getUserDetails?.payload?.pictureVersion}`
                    }
                  />

                  <div className="edit-upload-icon">
                    <input
                      type="file"
                      accept="image/*"
                      onChange={handleImageChange}
                      ref={photoInput}
                      style={{ display: "none" }}
                    />
                    <EditOutlined className="color-icon-primary" />
                  </div>
                </div>
              </div>
              <div className="w-70p">
                <p className="heading-h4-semibold">User Information</p>
                <div className="space-y-2 w-full mt-15">
                  <div className="flex gap-1">
                    <Text
                      name="firstName"
                      label="First Name"
                      error={!!errors.firstName}
                      helperText={errors.firstName?.message}
                    />
                    <Text
                      name="lastName"
                      label="Last Name"
                      error={!!errors.lastName}
                      helperText={errors.lastName?.message}
                    />
                  </div>

                  <div className="flex gap-1">
                    <Text
                      name="email"
                      label="Email Address"
                      error={!!errors.email}
                      disabled={navigatedFor === "editUser"}
                      helperText={errors.email?.message}
                    />
                    <PhoneInput
                      name="phoneNumber"
                      label="Phone number"
                      error={!!errors.phoneNumber}
                      helperText={errors.phoneNumber?.message}
                    />
                  </div>

                  <div className="flex gap-1">
                    <SearchableAutoComplete
                      name="permissions"
                      label="Permissions"
                      options={permDropdownOption}
                      placeholder="Select"
                      error={!!errors.state}
                      helperText={errors.state?.message}
                    />
                  </div>
                </div>
                {editableUser?.userId && (
                  <>
                    <div className="mt-125 mb-125">
                      <Divider />
                    </div>
                    <div className="mb-3 pb-025">
                      <ManagedEmployeeList customerUserId={editableUser.userId} managersList={managersList} />
                    </div>
                  </>
                )}
              </div>
            </div>
          </form>
        </FormProvider>
      </div>
    </>
  );
}

export default AddCustomerUser;
