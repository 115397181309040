import React from "react";
import { useSelector, useDispatch } from "react-redux";

import { Grid } from "@mui/material";

import { useFlags } from "launchdarkly-react-client-sdk";

import { usePermissions } from "components/Hook";
import { isEmptyObject } from "components/Screens/CommonUtils";
import PageLoader from "components/common/PageLoader";
import TabComponent from "components/common/Tabs";
import PageToolBar from "components/Widgets/PageToolBar";
import TopHeader from "components/common/TopHeader";

import { GetCustomerUserandCompanyProfileData } from "store/actions/Settings/CustomerUser&CompanyProfileData";

import UserPermissions from "./UserPermissions";
import UserList from "./UserList";
import GeneralInfo from "./GeneralInfo";
import CompanyInfo from "./CompanyInfo";
import ProfilePicture from "./Profile/ProfilePicture";

import "./Setting.scss";

function CustomerSetting() {
  const dispatch = useDispatch();

  const flags = useFlags();

  const [tab, setTab] = React.useState(0);

  const { loggedInUser, loggedInUserType } = usePermissions();

  const loadingAdditionalInfo = useSelector((state) => state.UserAdditionalInfo.loadingAdditionalInfo);

  React.useEffect(() => {
    const initialSelectedTab = {
      edge: 2,
      customer: 1,
    };
    const initialSelectedTabNew = {
      edge: 1,
      customer: 0,
    };

    setTab(flags?.teamManagement ? initialSelectedTabNew[loggedInUserType] : initialSelectedTab[loggedInUserType]);

    if (isEmptyObject(loggedInUser)) {
      const getProfileDataRequest = {
        customer: `customerUser/${loggedInUser?.userId}`,
        edge: `user/${loggedInUser?.userId}`,
      };

      dispatch(
        GetCustomerUserandCompanyProfileData({
          request: getProfileDataRequest[loggedInUserType],
        })
      );
    }
  }, [loggedInUser, loggedInUserType]);

  const getTabs = () => {
    if (loggedInUserType === "edge") {
      return [{ label: "Manage Users" }];
    }
    return [
      { label: "Company Information" },
      {
        label: "User Management",
      },
    ];
  };

  return (
    <>
      {loadingAdditionalInfo && <PageLoader />}
      <Grid sx={{ background: "#FFFFFF", minHeight: "calc(100vh - 51px)" }}>
        <TopHeader title="Account Details" linkName="Account" breadcrumbs backIcon="/main/dashboard" />

        <Grid
          container
          rowSpacing={3}
          sx={{
            marginTop: "25px",
          }}
        >
          {flags?.teamManagement ? (
            <div className="tab-container">
              <TabComponent tabs={getTabs()} tab={tab} setTab={setTab} />
            </div>
          ) : (
            <PageToolBar
              tab1Title={{
                title: loggedInUserType !== "edge" ? "Company Information" : null,
                navLink: null,
              }}
              tab2Title={{
                title: loggedInUserType !== "edge" ? "User Management" : "Manage Users",
                navLink: null,
              }}
              tab={tab}
              setTab={setTab}
            />
          )}
        </Grid>
        <Grid>
          {(flags?.teamManagement ? tab === 0 : tab === 1) && (
            <Grid
              sx={{
                maxWidth: "1076px",
                width: "100%",
                margin: "0px auto",
                marginTop: "24px",
                paddingTop: "0px",
              }}
            >
              <Grid
                sx={{
                  display: "flex",
                  alignItems: "start",
                  gap: "16px",
                }}
              >
                <Grid sx={{ minWidth: "712px" }}>
                  <GeneralInfo title="General Information" />
                </Grid>
                <Grid sx={{ maxWidth: "348px", width: "100%" }}>
                  <ProfilePicture page="companyProfile" />
                </Grid>
              </Grid>
              <Grid sx={{ maxWidth: "712px", marginTop: "16px" }}>
                <CompanyInfo title="Company Information" />
              </Grid>
            </Grid>
          )}
          {(flags?.teamManagement ? tab === 1 : tab === 2) && (
            <Grid
              sx={{
                maxWidth: "1076px",
                width: "100%",
                margin: "24px auto",
              }}
            >
              {flags?.teamManagement ? <UserList /> : <UserPermissions />}
            </Grid>
          )}
        </Grid>
      </Grid>
    </>
  );
}

export default CustomerSetting;
