/* eslint-disable */ 
// because this will is deprecated

import React from "react";
//material ui
import {
  Grid,
  Typography,
  Box,
  Divider,
  FormControl,
  Snackbar,
  Alert,
  InputAdornment,
  IconButton,
  Stack,
} from "@mui/material";
//enum
import { settingPages } from "../../SettingPages.enumn";
//icon
import { AiOutlineEdit } from "react-icons/ai";
import GoogleIcon from "../../../../Icons/googleIconSmall.png";
import VpnKeyOutlinedIcon from "@mui/icons-material/VpnKeyOutlined";
import EditIcon from "../../../../../assets/images/EditIcon.png";
import {
  EmailOutlined,
  VisibilityOutlined,
  VisibilityOffOutlined,
} from "@mui/icons-material";

//router
import { useNavigate } from "react-router-dom";
//redux dispatch
import { useSelector, useDispatch } from "react-redux";
import {
  GoogleSigunp,
  ResetGoogleSigunp,
} from "../../../../../store/actions/Google/Google_signup";
import GoogleLogin from "react-google-login";
import { baseUrl } from "../../../../../util/APIBaseUrl";
import {
  getError,
  getValue,
  isEmptyObject,
  isValidResponse,
} from "../../../CommonUtils";
import Button from "../../../../Button/Button";
import LockIcon from "../../../../../assets/svgs/LockIcon";
import TextFieldV2 from "../../../../common/TextFieldV2/TextFieldV2";
import {
  ResetChangeUserPassword,
  changeUserPasswordInfo,
} from "../../../../../store/actions/Settings/ChangeUserPassword";
import { usePermissions } from "../../../../Hook";
import { GetCustomerUserandCompanyProfileData } from "../../../../../store/actions/Settings/CustomerUser&CompanyProfileData";
import AlertError from "../../../../Widgets/AlertError";
import GmailIcon from "../../../../../assets/svgs/GmailIcon";
import PencilIcon from "../../../../../assets/svgs/PencilIcon";
import ChangePasswordV2 from "components/Screens/SettingV2/Profile/Password/ChangePasswordV2";

const initalPassword = {
  oldPassword: "",
  newPassword: "",
  confirmPassword: "",
};

function Password(props) {
  //props
  const { handleSettingPagechange } = props;
  //constants
  let history = useNavigate();

  //redux
  const dispatch = useDispatch();

  //custom hook
  const { loggedInUser, loggedInUserType } = usePermissions();

  //component states
  const [userProfileUrl, setUserProfileUrl] = React.useState(null);
  const [passwordScreen, setPasswordScreen] = React.useState("displayPassword");
  const [changePassword, setChangePassword] = React.useState(initalPassword);
  const [openAlert, setOpenAlert] = React.useState(false);
  const [passwordMatchFlag, setPasswordMatchFlag] = React.useState(false);
  const [saveButton, setSaveButton] = React.useState(false);
  const [showPassword, setShowPassword] = React.useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = React.useState(false);
  const [showChangePasswordDialog, setShowChangePasswordDialog] = React.useState(false); 

  //redux state
  const customerUserandCompanyProfileData = useSelector(
    (state) =>
      state.CustomerUserandCompanyProfileData.customerUserandCompanyProfileData
  );

  const googleSignupInfo = useSelector(
    (state) => state.GoogleSignup.googleSignupInfo
  );

  const platformConfig = useSelector(
    (state) => state.PlatformConfig.platformConfig
  );

  const changeUserPassword = useSelector(
    (state) => state.ChangeUserPassword.changeUserPassword
  );

  //functions
  const handleClickShowPassword = () => setShowPassword(!showPassword);

  const handleShowConfirmPassword = () =>
    setShowConfirmPassword(!showConfirmPassword);

  const handleInputChange = (e) => {
    setChangePassword({
      ...changePassword,
      [e.target.name]: e.target.value,
    });
  };

  const onLoginSuccess = async (googleData) => {
    if (googleData) {
      let googleRequest = {
        customer: `customerUser/${loggedInUser?.userId}/linkGoogle`,
        edge: `user/${loggedInUser?.userId}/linkGoogle`,
      };

      dispatch(
        GoogleSigunp({
          googleToken: googleData.code,
          method: "POST",
          request: googleRequest[loggedInUserType],
        })
      );
    }
  };

  const onLoginFailure = (response) => {
    //localStorage.removeItem("accessToken");
    //history("/");
    return (
      <Alert
        color="success"
        open={true}
        autoHideDuration={3500}
        variant="filled"
        icon={false}
        sx={{
          backgroundColor: forgotPasswordInfo?.ok ? "#38E25D" : "#F64A14",
          padding: "initial",
          px: "5px",
          fontSize: "12px",
          textAlign: "center",

          borderRadius: "5px !important",
          color: "#ffffff !important",
          "&.MuiPaper-root": {
            color: "#ffffff !important",
          },
        }}
      >
        <Typography
          sx={{
            fontFamily: "Poppins-Regular !important",
            fontWeight: "400",
            fontSize: "13px",
          }}
        >
          Unfortunatley there was an error linking you account with google,
          please try again.
        </Typography>
      </Alert>
    );
  };

  const handleSavePassword = () => {
    setSaveButton(true);
    if (changePassword.newPassword != "" && changePassword?.oldPassword != "") {
      if (changePassword.newPassword == changePassword.confirmPassword) {
        setPasswordMatchFlag(false);

        let req = {
          customer: `customerUser/${loggedInUser?.userId}/changePassword`,
          edge: `user/${loggedInUser?.userId}/changePassword`,
        };

        dispatch(
          changeUserPasswordInfo({
            userPassword: changePassword,
            request: req[loggedInUserType],
            userEmail: loggedInUser?.email,
          })
        );
      } else {
        setPasswordMatchFlag(true);
        setOpenAlert(true);
      }
    } else {
      setOpenAlert(true);
    }
  };

  //methods
  React.useEffect(() => {
    // let req = {
    //   customer: `customerUser/${loggedInUser?.userId}`,
    //   edge: `user/${loggedInUser?.userId}`,
    // };

    if (changeUserPassword?.ok) {
      // dispatch(
      //   GetCustomerUserandCompanyProfileData({
      //     request: req[loggedInUserType],
      //   })
      // );

      setPasswordScreen("displayPassword");
      setChangePassword(initalPassword);
    } else if (changeUserPassword?.ok == false) {
      setOpenAlert(true);
    }
  }, [changeUserPassword]);

  React.useEffect(() => {
    let accesstoken = localStorage.getItem("accessToken");

    if (
      googleSignupInfo?.ok &&
      loggedInUser.google != true &&
      loggedInUser.google != "true"
    ) {
      // if (accesstoken != null && accesstoken != "undefined") {
      //   history("/main/settings");
      // } else {
      //   //localStorage.removeItem("accessToken");
      //   //history("/");
      // }
    } else if (googleSignupInfo?.ok == false) {
      setOpenAlert(true);
    }
  }, [googleSignupInfo]);

  React.useEffect(() => {
    let userAdditionalInfo = JSON.parse(
      sessionStorage.getItem("userAdditionalInfo")
    );

    dispatch(ResetChangeUserPassword({}));
    dispatch(ResetGoogleSigunp({}));

    if (isEmptyObject(loggedInUser)) {
      let logo = {
        customer: `${baseUrl}/customerUser/${loggedInUser?.userId}/logo?id=${userAdditionalInfo?.pictureVersion}`,
        edge: `${baseUrl}/user/${loggedInUser?.userId}/logo?id=${userAdditionalInfo?.pictureVersion}`,
      };
      setUserProfileUrl(logo[loggedInUserType]);
    }
  }, []);

  return (
    <Grid
      sx={{
        backgroundColor: "#FFFFFF",
        padding: "12px",
        paddingBottom: "32px",
        borderRadius: "12px",
      }}
    >
      <Grid
        container
        direction="row"
        alignItems="center"
        justifyContent="space-between"
      >
        <Grid sx={{ display: "flex", alignItems: "center", gap: "10px" }}>
          <Grid item>
            <Box
              sx={{
                display: "flex",
                backgroundColor: "#F2FFFB",
                borderRadius: "99px",
                width: "42px",
                height: "42px",
                alignItems: "center",
                justifyContent: "center",
                padding: "9px",
              }}
            >
              <LockIcon />
            </Box>
          </Grid>

          <Grid item>
            <Typography
              sx={{
                color: "#14151F",
                fontFamily: "Poppins-Medium !important",
                fontSize: "16px",
                fontWeight: 500,
                lineHeight: "32px",
              }}
            >
              Change Password
            </Typography>
          </Grid>
        </Grid>

        {passwordScreen !== "changePassword" && (
          <Button
            variant="primary"
            size="sm"
            leadIcon={<PencilIcon />}
            style={{
              backgroundColor: "#F1EFFF",
              borderRadius: "99px",
              marginRight: "4px",
              borderColor: "transparent",
            }}
            onClick={() => {
              setShowChangePasswordDialog(true);
              dispatch(ResetGoogleSigunp({}));
              setPasswordScreen("changePassword");
            }}
          >
            <Typography variant="h5" color="primary">
              Edit
            </Typography>
          </Button>
        )}
      </Grid>

      {passwordScreen == "changePassword" ? (
        <Box
          sx={{
            mt: 2,
            ml: 1,
            mr: 2,
          }}
        >
          <Grid container xs={6} direction="column" spacing={2}>
            {/* old password */}
            <Grid item>
              <FormControl variant="outlined" error>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    border: "1px solid #B2B4E2",
                    borderRadius: "12px",
                  }}
                >
                  <TextFieldV2
                    label={"Type old password"}
                    variant="filled"
                    name={"oldPassword"}
                    id={"oldPassword"}
                    value={changePassword?.oldPassword}
                    onChange={(event) => handleInputChange(event)}
                    error={
                      saveButton
                        ? changePassword?.oldPassword != ""
                          ? false
                          : true
                        : false
                    }
                    sx={{
                      width: "100%",
                    }}
                  />
                </Box>
              </FormControl>
            </Grid>

            {/* new password */}
            <Grid item>
              <FormControl variant="outlined" fullWidth error>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    border: "1px solid #B2B4E2",
                    borderRadius: "12px",
                  }}
                >
                  <TextFieldV2
                    label={"Set password"}
                    variant="filled"
                    name={"newPassword"}
                    id={"newPassword"}
                    value={changePassword?.newPassword}
                    onChange={(event) => handleInputChange(event)}
                    error={
                      saveButton
                        ? changePassword?.newPassword != ""
                          ? false
                          : true
                        : false
                    }
                    type={showPassword ? "text" : "password"}
                    InputProps={{
                      disableUnderline: true,
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                          >
                            {showPassword ? (
                              <VisibilityOutlined
                                sx={{
                                  color: "#3D3E5C",
                                }}
                              />
                            ) : (
                              <VisibilityOffOutlined
                                sx={{
                                  color: "#3D3E5C",
                                }}
                              />
                            )}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                    sx={{
                      width: "100%",
                    }}
                  />
                </Box>
              </FormControl>
            </Grid>

            {/* confirm password */}
            <Grid item>
              <FormControl variant="outlined" fullWidth error>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    border: "1px solid #B2B4E2",
                    borderRadius: "12px",
                  }}
                >
                  <TextFieldV2
                    label={"Confirm Password"}
                    variant="filled"
                    name={"confirmPassword"}
                    id={"confirmPassword"}
                    error={
                      saveButton
                        ? changePassword?.confirmPassword != ""
                          ? false
                          : true
                        : false
                    }
                    type={showConfirmPassword ? "text" : "password"}
                    InputProps={{
                      disableUnderline: true,
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleShowConfirmPassword}
                          >
                            {showConfirmPassword ? (
                              <VisibilityOutlined
                                sx={{
                                  color: "#3D3E5C",
                                }}
                              />
                            ) : (
                              <VisibilityOffOutlined
                                sx={{
                                  color: "#3D3E5C",
                                }}
                              />
                            )}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                    value={changePassword?.confirmPassword}
                    onChange={(event) => handleInputChange(event)}
                    sx={{
                      width: "100%",
                    }}
                  />
                </Box>
              </FormControl>
            </Grid>
            {/* </Stack> */}
          </Grid>

          <Grid sx={{ mt: 2, display: "flex", gap: "6px" }}>
            <Button
              variant="primary"
              size="lg"
              disableElevation
              style={{
                borderRadius: "99px",
                width: "99px",
                height: "40px",
                fontFamily: "Poppins-Medium",
                fontSize: "13px",
                lineHeight: "20px",
              }}
              onClick={handleSavePassword}
            >
              Save
            </Button>

            <Button
              onClick={() => {
                setSaveButton(false);
                setOpenAlert(false);
                dispatch(ResetGoogleSigunp({}));
                dispatch(ResetChangeUserPassword({}));
                setChangePassword(initalPassword);
                setPasswordScreen("displayPassword");
              }}
              variant="ghost"
              size="lg"
              style={{
                borderRadius: "99px",
                width: "99px",
                height: "40px",
                fontFamily: "Poppins-Medium",
                fontSize: "13px",
                lineHeight: "20px",
                color: "#3D43BB",
              }}
            >
              Cancel
            </Button>

            <Grid item textAlign="center" sx={{ willChange: "transform" }}>
              <AlertError
                openAlert={openAlert}
                setOpenAlert={setOpenAlert}
                errors={[changeUserPassword]}
                passwordMatch={passwordMatchFlag}
              />
            </Grid>
          </Grid>
        </Box>
      ) : (
        <Grid
          item
          container
          direction="column"
          sx={{
            mt: 2,
            ml: 1,
            mr: 2,
          }}
        >
          <Grid
            item
            container
            justifyContent="space-between"
            alignItems="center"
            pl={4}
            pr={8}
          >
            {!loggedInUser?.google ? (
              <>
                <Grid item>
                  <Typography
                    variant="h3"
                    fontSize="14px"
                    sx={{ fontFamily: "Poppins-Regular" }}
                  >
                    Password
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography
                    variant="h5"
                    fontSize="14px"
                    sx={{ fontFamily: "Poppins-Medium" }}
                  >
                    **********
                  </Typography>
                </Grid>
              </>
            ) : null}
          </Grid>
          <Grid item pl={4} pr={8}>
            <Divider
              light
              sx={{
                my: 0.75,
                "&.MuiDivider-root": {
                  borderColor: "#d9daf266",
                  borderWidth: "0.1px",
                },
              }}
            />
          </Grid>
          <Grid
            item
            container
            justifyContent="space-between"
            alignItems="center"
            pl={4}
            pr={8}
          >
            <Grid item>
              <Typography
                variant="h3"
                fontSize="14px"
                sx={{ fontFamily: "Poppins-Regular" }}
              >
                Email
              </Typography>
            </Grid>
            <Grid item>
              <Typography
                variant="h5"
                fontSize="14px"
                sx={{ fontFamily: "Poppins-Medium" }}
              >
                {getValue(customerUserandCompanyProfileData?.payload?.email)}
              </Typography>
            </Grid>
          </Grid>

          <>
            {/* <Grid item sx={{ pt: 3, pl: 4 }}> */}
            <Stack direction="row" spacing={2} sx={{ pt: 3, pl: 4 }}>
              {!loggedInUser?.google ? (
                <GoogleLogin
                  // clientId="953999418916-g6ht104mq1rdooi3iickb4olava8qj3k.apps.googleusercontent.com"
                  // clientId="446270886612-v9di2rg3kg9qgjbvvsd6o0vc34dsl4s2.apps.googleusercontent.com"
                  clientId={
                    platformConfig.ok &&
                    platformConfig?.payload?.GOOGLE_CLIENT_ID
                  }
                  accessType="offline"
                  approvalPrompt="force"
                  prompt="consent"
                  responseType="code"
                  render={(renderProps) => (
                    <Button
                      variant="outlined"
                      size="lg"
                      disableElevation
                      leadIcon={<img src={GoogleIcon} height={22} width={22} />}
                      style={{
                        borderColor: "#3D43BB",
                        color: "#3D43BB",
                        fontSize: "16px",
                        lineHeight: "20px",
                        fontFamily: "Poppins-Medium",
                      }}
                      onClick={renderProps.onClick}
                    >
                      Link with Google
                    </Button>
                  )}
                  onSuccess={onLoginSuccess}
                  onFailure={onLoginFailure}
                  cookiePolicy={"single_host_origin"}
                />
              ) : (
                <Typography
                  variant="h2"
                  sx={{
                    fontFamily: "Poppins-Regular",
                    color: "#3D43BB",
                  }}
                >
                  <GmailIcon /> Linked with Google
                </Typography>
              )}

              <AlertError
                openAlert={openAlert}
                setOpenAlert={setOpenAlert}
                errors={[googleSignupInfo]}
              />
            </Stack>
          </>
        </Grid>
      )}
      {showChangePasswordDialog && (
        <ChangePasswordV2 open={showChangePasswordDialog} onClose={() => setShowChangePasswordDialog(false)} />
      )}
    </Grid>
  );
}

export default Password;
