import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import { ResetGetUserDetails } from "store/actions/Settings/GetUserDetails";
import { ManageUser } from "store/actions/Settings/ManageUser";

import { usePermissions } from "components/Hook";
import CustomButton from "components/common/Button";

import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import AddUser from "components/Screens/SettingV2/AddUser/AddUserV2";
import UserItem from "./UserItem";
import RemoveUser from "components/Screens/SettingV2/UserPermissions/RemoveUserV2";

function Index() {
  const dispatch = useDispatch();
  const { loggedInUserType } = usePermissions();

  const [addorEditUser, setAddorEditUser] = useState({
    open: false,
    mode: null,
  });
  const [customerId, setCustomerId] = useState("");
  const [editableUser, setEditAbleUser] = useState({});
  const [openRemoveUser, setOpenRemoveUser] = useState(false);

  const loggedInUser = useMemo(() => JSON.parse(localStorage.getItem("loggedInUser")), []);

  const manageUser = useSelector((state) => state.ManageUser.manageUser);

  const getAccountOwnerAndUsers = (users) => {
    if (!users) return { accountOwner: null, currentUser: null, otherUsers: [] };

    const accountOwner = users.find((user) => user.mainUser === true);
    const currentUser = users.find((user) => user.email === loggedInUser?.email);
    const otherUsers = users.filter((user) => !user.mainUser && user.email !== loggedInUser?.email);

    return { accountOwner, currentUser, otherUsers };
  };

  const handleRemoveUser = (user) => {
    setOpenRemoveUser(true);
    setEditAbleUser(user);
  };

  const handleCloseRemoveUser = () => {
    setOpenRemoveUser(false);
    setEditAbleUser({});
  };

  const fetchUserList = useCallback(
    (userAdditionalInfo) => {
      let manageUserRequest = "";
      if (
        !addorEditUser.open &&
        userAdditionalInfo &&
        Object.keys(userAdditionalInfo).length &&
        Object.keys(loggedInUser).length &&
        loggedInUser.userId
      ) {
        if (loggedInUser?.type == "customer") {
          manageUserRequest = `customer/${userAdditionalInfo?.customerId}/customerUsers`;
        } else if (loggedInUser?.type == "edge") {
          manageUserRequest = "users";
        }

        dispatch(
          ManageUser({
            request: manageUserRequest,
            rowsPerPage: 50,
            page: 0,
            sortBy: {
              field: "First_Name",
              direction: "asc",
            },
          })
        );
      }
    },
    [addorEditUser]
  );

  useEffect(() => {
    const userAdditionalInfo = JSON.parse(sessionStorage.getItem("userAdditionalInfo"));
    setCustomerId(userAdditionalInfo?.customerId);
    fetchUserList(userAdditionalInfo);
    dispatch(ResetGetUserDetails({}));
  }, [addorEditUser]);

  const { accountOwner, currentUser, otherUsers } = manageUser?.ok
    ? getAccountOwnerAndUsers(manageUser.payload.rows)
    : { accountOwner: null, currentUser: null, otherUsers: [] };

  return (
    <div>
      {addorEditUser.open === false ? (
        <div>
          <div className="flex justify-between">
            <div className="heading-h3-semibold">Current Users</div>
            <CustomButton
              size="large"
              onClick={() =>
                setAddorEditUser({
                  open: true,
                  mode: "addUser",
                })
              }
              startIcon={<AddOutlinedIcon />}
              label={loggedInUserType !== "edge" ? "Add New User" : "Add Edge Employee"}
            />
          </div>
          <div className="users-list">
            {accountOwner && (
              <UserItem
                user={accountOwner}
                loggedInUser={loggedInUser}
                setEditAbleUser={setEditAbleUser}
                setAddorEditUser={setAddorEditUser}
                handleRemoveUser={handleRemoveUser}
              />
            )}
            {currentUser && currentUser.email !== accountOwner?.email && (
              <UserItem
                user={currentUser}
                loggedInUser={loggedInUser}
                setEditAbleUser={setEditAbleUser}
                setAddorEditUser={setAddorEditUser}
                handleRemoveUser={handleRemoveUser}
              />
            )}
            {otherUsers.map((user) => (
              <UserItem
                user={user}
                loggedInUser={loggedInUser}
                setEditAbleUser={setEditAbleUser}
                setAddorEditUser={setAddorEditUser}
                handleRemoveUser={handleRemoveUser}
              />
            ))}
          </div>
          <RemoveUser
            openRemoveUser={openRemoveUser}
            setOpenRemoveUser={setOpenRemoveUser}
            editableUser={editableUser}
            handleCloseRemoveUser={handleCloseRemoveUser}
          />
        </div>
      ) : (
        <AddUser
          navigatedFor={addorEditUser.mode}
          paramId={addorEditUser.mode === "editUser" ? editableUser.userId : null}
          addorEditUser={addorEditUser}
          editableUser={addorEditUser.mode === "editUser" ? editableUser : null}
          setAddorEditUser={setAddorEditUser}
          managersList={manageUser?.ok ? manageUser.payload.rows : []}
          customerId={customerId}
        />
      )}
    </div>
  );
}

export default Index;
