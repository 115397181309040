import React, { useMemo, useState } from "react";
import { useForm, FormProvider } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useDispatch } from "react-redux";
import * as yup from "yup";

import Text from "components/common/Text";
import Button from "components/common/Button";
import PhoneInput from "components/common/PhoneInput";
import { AlertType } from "components/common/Snackbar";
import { getValue } from "components/Screens/CommonUtils";
import SearchableAutoComplete from "components/common/AutoComplete";
import Dialog, { DialogTitle, DialogContent, DialogActions } from "components/common/Dialog";

import { GetCustomerUserandCompanyProfileData } from "store/actions/Settings/CustomerUser&CompanyProfileData";
import { showSnackbar } from "store/actions/Utility";
import { customerUpdateGeneralInformation } from "services/customer";

import "components/Screens/SettingV2/Profile/PersonalInfo/EditGeneralInformation/EditGeneralInformation.scss";

function EditGeneralInformationModal({ onClose, generalInformation }) {
  const [loading, setLoading] = useState(false);

  const defaultValues = {
    firstName: getValue(generalInformation?.firstName),
    lastName: getValue(generalInformation?.lastName),
    email: getValue(generalInformation?.email),
    phoneNumber: getValue(generalInformation?.phoneNumber),
    country: getValue(generalInformation?.country),
    timezone: getValue(generalInformation?.timezone),
  };

  const countriesList = useMemo(() => JSON.parse(sessionStorage.getItem("dropdownData"))?.payload?.country || [], []);
  const countries = countriesList?.map(({ countryCode }) => ({
    value: countryCode,
    label: countryCode,
  }));

  const timeZoneList = useMemo(() => JSON.parse(sessionStorage.getItem("dropdownData"))?.payload?.timezone || [], []);
  const timezoneOptions = timeZoneList?.map(({ timezone }) => ({
    label: timezone,
    value: timezone,
  }));

  const generalInformationSchema = yup.object().shape({
    firstName: yup.string().required("First name is required"),
    lastName: yup.string().required("Last name is required"),
    phoneNumber: yup.string().nullable(),
    country: yup.string().required("Country is required"),
    timezone: yup.string().required("Timezone is required"),
  });

  const methods = useForm({
    resolver: yupResolver(generalInformationSchema),
    mode: "onChange",
    defaultValues,
  });
  const dispatch = useDispatch();

  const {
    handleSubmit,
    formState: { errors, isValid },
  } = methods;

  const onSubmit = async (data) => {
    try {
      setLoading(true);
      await customerUpdateGeneralInformation(data);
      onClose();
      dispatch(
        GetCustomerUserandCompanyProfileData({
          request: `/customerUser/${generalInformation?.userId}`,
        })
      );
      dispatch(
        showSnackbar({
          type: AlertType.success,
          message: "Your general information has been updated successfully.",
          dispatch,
        })
      );
    } catch (error) {
      showSnackbar({ type: AlertType.error, message: error?.message, dispatch });
    } finally {
      setLoading(false);
    }
  };

  return (
    <Dialog
      title={<DialogTitle title="General Information" />}
      content={
        <FormProvider {...methods}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <DialogContent>
              <div className="edit-modal-container">
                <Text
                  label="First Name"
                  name="firstName"
                  type="text"
                  error={!!errors.firstName}
                  helperText={errors.firstName?.message}
                />
                <Text
                  label="Last Name"
                  name="lastName"
                  type="text"
                  error={!!errors.lastName}
                  helperText={errors.lastName?.message}
                />
                <Text
                  label="Email Address"
                  name="email"
                  type="text"
                  disabled
                  error={!!errors.email}
                  helperText={errors.email?.message}
                />
                <PhoneInput
                  name="phoneNumber"
                  label="Phone number"
                  isOptional
                  error={!!errors.phoneNumber}
                  helperText={errors.phoneNumber?.message}
                  placeholder="Enter phone number"
                />
                <SearchableAutoComplete
                  label="Country / Region"
                  name="country"
                  placeholder="Select country"
                  options={countries}
                  error={!!errors.country}
                  helperText={errors.country?.message}
                />
                <SearchableAutoComplete
                  label="Time Zone"
                  name="timezone"
                  placeholder="Select timezone"
                  options={timezoneOptions}
                  helperText={errors.timezone?.message}
                  error={!!errors.timezone}
                />
              </div>
            </DialogContent>
          </form>
        </FormProvider>
      }
      actions={
        <DialogActions
          action1={<Button onClick={() => onClose(false)} variant="text" label="Cancel" size="large" />}
          action2={
            <Button
              onClick={handleSubmit(onSubmit)}
              isDisabled={!isValid}
              isLoading={loading}
              label="Save"
              size="large"
            />
          }
        />
      }
      open
      onClose={() => onClose(false)}
      size="sm"
    />
  );
}

export default EditGeneralInformationModal;
