import React, { useState } from "react";

import InfoSection from "components/common/InfoSection";
import ProfileInformation from "./ProfileInformation";
import ChangePassword from "./ChangePassword";
import { getValue } from "components/Screens/CommonUtils";
import ManagedEmployeeList from "components/Screens/SettingV2/AddUser/ManagedEmployeeList";
import ChangePasswordV2 from "components/Screens/SettingV2/Profile/Password/ChangePasswordV2";
import EditGeneralInformationModal from "components/Screens/SettingV2/Profile/PersonalInfo/EditGeneralInformation";

function GeneralInformation(props) {
  const [showChangePasswordDialog, setShowChangePasswordDialog] = useState(false);
  const [showGeneralInformationDialog, setShowGeneralInformationDialog] = useState(false);
  const { customerUserandCompanyProfileData, managersList } = props;
  const { payload: data } = customerUserandCompanyProfileData;

  const contactInfo = [
    { label: "First Name", value: getValue(data?.firstName) },
    { label: "Last Name", value: getValue(data?.lastName) },
    { label: "Email", value: getValue(data?.email) },
    { label: "Phone number", value: getValue(data?.phoneNumber) || "N/A" },
    { label: "Country / Region", value: getValue(data?.country) },
    { label: "Time Zone", value: getValue(data?.timezone) },
  ];

  const passwordInfo = [
    { label: "Password", value: "********" },
    { label: "Email", value: getValue(data?.email) },
  ];

  return (
    <div className="pb-5">
      <InfoSection
        header="General Information"
        position="top"
        editIcon
        onEdit={() => setShowGeneralInformationDialog(true)}
        noBottomBorder
      >
        <ProfileInformation contactInfo={contactInfo} />
      </InfoSection>
      <InfoSection
        header="Change Password"
        position="middle"
        editIcon
        onEdit={() => setShowChangePasswordDialog(true)}
        noBottomBorder
      >
        <ChangePassword contactInfo={passwordInfo} />
      </InfoSection>
      <InfoSection header="" position="bottom" onEdit={() => {}}>
        <ManagedEmployeeList customerUserId={data?.userId} managersList={managersList} />
      </InfoSection>
      {showChangePasswordDialog && <ChangePasswordV2 onClose={() => setShowChangePasswordDialog(false)} />}
      {showGeneralInformationDialog && (
        <EditGeneralInformationModal onClose={() => setShowGeneralInformationDialog(false)} generalInformation={data} />
      )}
    </div>
  );
}

export default GeneralInformation;
