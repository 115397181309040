import React, { useState } from "react";
import { useForm, FormProvider, useWatch } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useDispatch } from "react-redux";
import * as yup from "yup";

import Dialog, { DialogTitle, DialogContent, DialogActions } from "components/common/Dialog";
import Text from "components/common/Text";
import Button from "components/common/Button";

import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";

import { customerChangePassword } from "services/customer";

import "components/Screens/SettingV2/Profile/Password/ChangePasswordV2/ChangePasswordV2.scss";
import { AlertType } from "components/common/Snackbar";

import { showSnackbar } from "store/actions/Utility";

const passwordRequirements = [
  {
    regex: /.{8,}/,
    message: "Must be at least 8 characters long",
  },
  {
    regex: /^(?=.*[a-z])(?=.*[A-Z]).*$/,
    message: "Must include a mix of uppercase and lowercase characters",
  },
  {
    regex: /[0-9]/,
    message: "Must include numbers",
  },
  {
    regex: /[@$!%*?&#]/,
    message: "Must include special characters",
  },
];

const passwordSchema = yup.object().shape({
  oldPassword: yup.string().required("Old password is required"),
  newPassword: yup
    .string()
    .required("New password is required")
    .test("requirements", "Password must meet all requirements", (value) =>
      passwordRequirements.every((req) => req.regex.test(value))
    ),
  confirmPassword: yup
    .string()
    .oneOf([yup.ref("newPassword"), null], "Passwords match")
    .required("Please confirm your new password"),
});

function ChangePasswordV2({ onClose }) {
  const [loading, setLoading] = useState(false);
  const methods = useForm({
    resolver: yupResolver(passwordSchema),
    mode: "onChange",
  });
  const dispatch = useDispatch();

  const {
    handleSubmit,
    setError,
    formState: { errors, isValid },
  } = methods;

  const newPassword = useWatch({ control: methods.control, name: "newPassword" });
  const confirmPassword = useWatch({ control: methods.control, name: "confirmPassword" });

  const validationStates = {
    ...Object.fromEntries(passwordRequirements.map((req) => [req.message, req.regex.test(newPassword)])),
    match: newPassword === confirmPassword,
  };

  const onSubmit = async (data) => {
    const user = JSON.parse(localStorage.getItem("loggedInUser"));
    const updatedData = { ...data, email: user.email, password: data.oldPassword };
    try {
      setLoading(true);
      await customerChangePassword(user.userId, updatedData);
      onClose();
      dispatch(
        showSnackbar({ type: AlertType.success, message: "Your password has been changed successfully.", dispatch })
      );
    } catch (error) {
      setError("oldPassword", {
        message: error?.message || "Something went wrong",
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <Dialog
      title={<DialogTitle title="Change Password" />}
      content={
        <FormProvider {...methods}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <DialogContent>
              <div className="mb-125">
                <Text
                  name="oldPassword"
                  label="Old password"
                  type="password"
                  error={!!errors.oldPassword}
                  helperText={errors.oldPassword?.message}
                  showPasswordEye
                />
              </div>
              <div className="mb-125">
                <Text
                  name="newPassword"
                  label="New password"
                  type="password"
                  error={!!errors.newPassword}
                  showPasswordEye
                  hideHelperText
                />
                <div className="mt-075">
                  {passwordRequirements.map((req) => (
                    <ValidationItem
                      key={req.message}
                      isValid={validationStates[req.message]}
                      message={req.message}
                      isDisabled={!newPassword}
                    />
                  ))}
                </div>
              </div>
              <Text
                name="confirmPassword"
                label="Retype new password"
                type="password"
                error={!!errors.confirmPassword}
                showPasswordEye
                hideHelperText
              />
              <div className="mt-075">
                <ValidationItem
                  isValid={validationStates.match}
                  message="Passwords match"
                  isDisabled={!confirmPassword}
                />
              </div>
            </DialogContent>
          </form>
        </FormProvider>
      }
      actions={
        <DialogActions
          action1={<Button onClick={() => onClose()} variant="text" label="Cancel" size="large" />}
          action2={
            <Button
              onClick={handleSubmit(onSubmit)}
              isDisabled={!isValid}
              isLoading={loading}
              className="text-blue-600"
              label="Change Password"
              size="large"
            />
          }
        />
      }
      open
      onClose={() => onClose()}
      size="xs"
    />
  );
}

function ValidationItem({ isValid, message, isDisabled }) {
  let textColor;
  if (isDisabled) {
    textColor = "disabled-color";
  } else if (isValid) {
    textColor = "valid-color";
  } else {
    textColor = "invalid-color";
  }

  return (
    <div className={`flex items-center ${textColor} mt-025`}>
      {isDisabled || isValid ? (
        <CheckCircleOutlineIcon fontSize="16px" className={`${textColor}-icon`} />
      ) : (
        <HighlightOffIcon fontSize="16px" className={`${textColor}-icon`} />
      )}
      <p className="ml-05 para-body-s-regular">{message}</p>
    </div>
  );
}

export default ChangePasswordV2;
